import React, { useState } from 'react';
import api from '../services/api';

export default function AddPassword() {
  const [service, setService] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Função para enviar a nova senha para o backend
  const handleAddPassword = async (e) => {
    e.preventDefault();

    try {
      await api.post('/passwords', { service, username, password });
      alert('Senha adicionada com sucesso!');
      setService('');
      setUsername('');
      setPassword('');
    } catch (error) {
      alert('Erro ao adicionar a senha');
    }
  };

  return (
    <div>
      <h1>Adicionar Nova Senha</h1>
      <form onSubmit={handleAddPassword}>
        <input
          type="text"
          placeholder="Serviço"
          value={service}
          onChange={(e) => setService(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Nome de Usuário"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Adicionar Senha</button>
      </form>
    </div>
  );
}

