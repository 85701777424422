import React, { useEffect, useState } from 'react';

export default function Home() {

	const handleNavigate = (url) => {
		window.location.href = url;
	};
  
	return (
		<section className="container">
            <div className="title">
                Password Center
            </div>
            <div className="poweredBy">
                <p>powered by <span className="logo-mini" onClick={() => handleNavigate('https://el3.dev/')}>el3</span></p>
            </div>
            <div className="buttons">
                <button onClick={() => handleNavigate('/login')}>Sign in</button>
                <button onClick={() => handleNavigate('/signup')}>Create your account</button>
            </div>
        </section>
	);

}