import React, { useEffect, useState } from 'react';
import api from '../services/api';

export default function Dashboard() {
  	const [passwords, setPasswords] = useState([]);

  	// Função para carregar as senhas do usuário ao montar o componente
	useEffect(() => {
		const fetchPasswords = async () => {
			try {
				const response = await api.get('/passwords');
				setPasswords(response.data);
			} catch (error) {
				alert('Erro ao carregar as senhas');
			}
		};

		fetchPasswords();
	}, []);

	// Função para copiar a senha para o clipboard
	const copyToClipboard = (password) => {
		navigator.clipboard.writeText(password).then(() => {
			alert('Senha copiada para o clipboard!');
		});
	};
	
	const handleNavigate = (url) => {
		window.location.href = url;
	};

	return (
		<div>
			<h1>Suas Senhas</h1>
			<div className="buttons">
				<button onClick={() => handleNavigate('/add-password')}>Add new pass</button>
			</div>
			<ul>
				{passwords.map((item) => (
				<li key={item._id}>
					<strong>Serviço:</strong> {item.service} <br />
					<strong>Usuário:</strong> {item.username} <br />
					<button onClick={() => copyToClipboard(item.encryptedPassword)}>
					Copiar Senha
					</button>
				</li>
				))}
			</ul>
		</div>
	);
}

