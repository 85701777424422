// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    color: #fff;
}

.title {
    max-width: 1280px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.poweredBy {
    margin-top: 10px;
    width: unset;
    position: relative;
    font-size: 12px;
}

.logo-mini {
    font-weight: bold;
    letter-spacing: -1; 
    border: 1px solid #fff; 
    border-radius: 15px;
    padding: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.logo-mini:hover {
    color: rgb(3 13 33);
    background-color: #fff;
}

.buttons {
    position: relative;
}

.buttons > button {
    width: 100%;
    display: block;
    padding: 15px 100px;
    cursor: pointer;
    margin: 15px 0;

    font-weight: bold;
    font-size: 16px;
    font-variant: small-caps;
    font-variant: all-small-caps;
    
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
}

.buttons > button:hover {
    background-color: #fff;
    color: rgb(3 13 33);
}`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,cAAc;;IAEd,iBAAiB;IACjB,eAAe;IACf,wBAAwB;IACxB,4BAA4B;;IAE5B,sBAAsB;IACtB,kBAAkB;IAClB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".container {\n    text-align: center;\n    font-weight: bold;\n    font-size: 36px;\n    color: #fff;\n}\n\n.title {\n    max-width: 1280px;\n    padding-left: 1rem;\n    padding-right: 1rem;\n}\n\n.poweredBy {\n    margin-top: 10px;\n    width: unset;\n    position: relative;\n    font-size: 12px;\n}\n\n.logo-mini {\n    font-weight: bold;\n    letter-spacing: -1; \n    border: 1px solid #fff; \n    border-radius: 15px;\n    padding: 5px;\n    margin-right: 5px;\n    cursor: pointer;\n}\n\n.logo-mini:hover {\n    color: rgb(3 13 33);\n    background-color: #fff;\n}\n\n.buttons {\n    position: relative;\n}\n\n.buttons > button {\n    width: 100%;\n    display: block;\n    padding: 15px 100px;\n    cursor: pointer;\n    margin: 15px 0;\n\n    font-weight: bold;\n    font-size: 16px;\n    font-variant: small-caps;\n    font-variant: all-small-caps;\n    \n    border: 1px solid #fff;\n    border-radius: 5px;\n    background-color: transparent;\n    color: #fff;\n}\n\n.buttons > button:hover {\n    background-color: #fff;\n    color: rgb(3 13 33);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
